import ModalContainer from '../../../Global/ModalContainer';
import SbUtils from "../../../../Utils/SbUtils";
import { LikesIcon, ViewsIcon, TikTokIcon, PreviousIcon, NextIcon, CloseIcon } from "../../../../Utils/icons";
import useOutsideClick from '../../../../Utils/useOutsideClick';
import { useEffect } from 'react';

const LightboxModal = ({
    closeModal,
    post,
    feedSettings,
    headerData,
    handleNext,
    handlePrev,
    showNext,
    showPrev,
    isPro,
    isLightboxVisible,
    setIsLightboxVisible
}) => {

    let captionDescription = post?.embed_html?.match(/<p>(.*?)<\/p>/)?.[1];
    // if multiple spaces are present in the caption, replace them with a break
    if (captionDescription?.includes('  ')) {
        captionDescription = captionDescription?.replace(/  /g, '<br /><br />');
    }

    const ref = useOutsideClick(() => {
        closeModal()
    });

    useEffect(() => {
        if (isLightboxVisible) {
            document.body.classList.add('sbt-lightbox-modal-open');
        } else {
            document.body.classList.remove('sbt-lightbox-modal-open');
        }
    }, [isLightboxVisible])

    return <>
        <ModalContainer
            size={isPro ? 'lightbox' : 'lightbox-free'}
            type={feedSettings?.feedTemplate}
            onClose={closeModal}
            className="sbt-lightbox-modal"
        >
            <div className="sbt-lightbox-modal-wrapper" ref={ref}>
                <div className="sbt-lightbox-modal-close"
                    onClick={closeModal}
                >
                    <CloseIcon />
                </div>

                <div className="sbt-lightbox-modal-iframe-wrap">
                    <iframe
                        src={`https://www.tiktok.com/embed/v2/${post?.id}`}
                        title={post?.video_description || 'TikTok Video'}
                        height={'758px'}
                        width={'325px'}
                        style={{
                            border: 'none',
                            visibility: 'unset',
                            margin: '0 auto',
                        }}
                    />
                </div>

                {isPro && 
                    <div className='sbt-lightbox-modal-content'>
                        <a className='sbt-lightbox-modal-header'
                            href={`https://www.tiktok.com/@${headerData?.username}`}
                            target='_blank'
                        >
                            <img className='sbt-lightbox-modal-avatar'
                                src={headerData?.local_avatar_url || headerData?.avatar_url_100}
                                alt={headerData?.bio_description || headerData?.username}
                            />

                            <div>
                                <p className='sbt-lightbox-modal-username'>
                                    @{headerData?.username}
                                </p>
                                <p className='sbt-lightbox-modal-date'>
                                    {SbUtils.printDate(post?.create_time, feedSettings)}
                                </p>
                            </div>
                        </a>

                        <div className='sbt-lightbox-modal-caption'
                            dangerouslySetInnerHTML={{
                                __html: SbUtils.printText(captionDescription, feedSettings, true)
                            }}
                        >
                        </div>

                        <div className='sbt-lightbox-modal-footer'>
                            <div className='sbt-lightbox-modal-stats'>
                                <span className='sbt-lightbox-modal-views'>
                                    <ViewsIcon fillColor={'black'} />
                                    {post?.view_count}
                                </span>

                                <span className='sbt-lightbox-modal-likes'>
                                    <LikesIcon fillColor={'black'} />
                                    {post?.like_count}
                                </span>
                            </div>

                            <a
                                href={post?.share_url}
                                target='_blank'
                            >
                                <TikTokIcon />
                            </a>
                        </div>
                    </div>
                }

                <div
                    onClick={handlePrev}
                >
                    <PreviousIcon className="sbt-lightbox-modal-prev"
                        style={{
                            display: showPrev ? 'block' : 'none'
                        }} />
                </div>

                <div
                    onClick={handleNext}
                >
                    <NextIcon className="sbt-lightbox-modal-next"
                        style={{
                            display: showNext ? 'block' : 'none'
                        }} />
                </div>
            </div>
        </ModalContainer >
    </>
}

export default LightboxModal;

